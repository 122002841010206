.App {
  text-align: center;
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between; 
  background-color: #282828;
}

a{
    color: #e0e0e0;
}

.App-header {
  position: fixed;
  width: 100%;
  background-color: #000000;
  height: 5vh;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}
.filt-logo {
    height: 95%;
  pointer-events: none;
}
.pic{
    width: 100%;
    border-radius: 10px;
    margin-top: 20px;
}

/*@media (prefers-reduced-motion: no-preference) {
  .filt-logo {
    animation: App-logo-spin infinite 5s linear;
  }
}*/

.body{ 
    height: 'auto';
    background-color: #282828;
    display: flex;
    padding-top: 50px;
    margin-bottom: 8vh;
    flex-direction: row;
    justify-content: space-around;
    align-items: top;
}
.tab{
    height: 'auto';
    width: 80%;
    display: flex;

    flex-direction: column;
    justify-content: top;
    align-items: center;
}
.spacer{
    height: 7vh;
    width: 0px;
    background-color: #282828;
}
.innertab{
    height: 'auto';
    width: 80%;
    background-color: #353535;
    margin: 10px;
    padding: 15px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
}
.form{
    min-width: 60%;
    max-width: 95%;
}
.formItem{
    display: flex;
    margin: 10px;
    width: 100%;
    flex-direction: row;
    align-items: center;
}
.withlabel{
    justify-content: space-between;
}
.submit{
    justify-content: center;
}
.confirmation{
    justify-content: center;
    font-size: 10pt;
}
.good{
    color: #00ff00;
}
.bad{
    color: #ff0000;
}
.infoPanel{
    color: #e0e0e0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Arial';
    font-style: italic;
}
.infoText{
    margin-top: 25px;
    margin-bottom: 10px;
}
.content_open{
    height: 550px;
    transition: height ease 0.9s;
    overflow: hidden;
}/*
.bookingsContentOpen{
    height: 400px;
    transition: height ease 0.9s;
}
.contactContentOpen{
    height: 400px;
    transition: height ease 0.9s;
}
.referencesContentOpen{
    height: 400px;
    transition: height ease 0.9s;
}*/
.content_closed {
    height: 0px;
    margin: 0px;
    overflow: hidden;
    transition: height ease 0.9s;
}
.divider{
    height: 15px;
    width: 'auto';
}
.tabText {
    width: 100%;
    padding: 0px;
    margin: 0px;
    height: 30px;
    font-family: 'Arial';
    font-weight: bold;
    font-style: italic;
    color: #e0e0e0;
    font-size: 20pt;
}

@media only screen and (max-width: 640px){
.body{
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: ;
    }
.tab{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 5px;
    }

.content_open{

}
.content_closed {
    height: 0px;

}
}
.bottomBar {
    align-self: center;
    background-color: #000000;
    height: 5vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0px;
    min-height: 30px;
}
.fininfo{
    font-family: 'Arial';
    font-weight: bold;
    font-style: italic;
    color: #353535;
    font-size: 8pt;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(-20deg);
  }
  50%{transform: rotate(20deg);}
  to {
    transform: rotate(-20deg);
  }
}
